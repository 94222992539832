@if (isLoggedIn$ | ngrxPush) {
  <schaeffler-app-shell
    [appTitle]="title"
    [appTitleLink]="titleLink"
    [hasSidebarLeft]="true"
    [userName]="username$ | ngrxPush"
    [userImageUrl]="profileImage$ | ngrxPush"
    [footerLinks]="footerLinks"
    [footerFixed]="false"
    [appVersion]="appVersion"
  >
    <ng-container sidenavBody>
      <div class="px-5">
        <div class="py-4">
          <cdba-user-settings></cdba-user-settings>
        </div>
        <mat-divider></mat-divider>
        <div class="py-4">
          <cdba-role-descriptions></cdba-role-descriptions>
        </div>
        @if (hasBetaUserRole$ | ngrxPush) {
          <mat-divider></mat-divider>
          <div class="py-4">
            <cdba-beta-feature-settings></cdba-beta-feature-settings>
          </div>
        }
      </div>
    </ng-container>
    <ng-container mainContent>
      <div
        class="w-full"
        [ngClass]="{ 'h-full': !(showCookiesSettings$ | ngrxPush) }"
      >
        <router-outlet></router-outlet>
      </div>
      <cdba-browser-support-detector></cdba-browser-support-detector>
      <div
        class="mx-auto max-w-screen-md bg-surface-legacy"
        *transloco="let t; read: 'legal'"
        [ngClass]="{ hidden: !(showCookiesSettings$ | ngrxPush) }"
      >
        <div class="py-3 px-4">
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">
            {{ t('cookieSettings') }}
          </button>
        </div>
        <div class="py-3 md:px-4">
          <div id="ot-sdk-cookie-policy"></div>
        </div>
      </div>
    </ng-container>
  </schaeffler-app-shell>
} @else {
  <cdba-loading-spinner [show]="true"></cdba-loading-spinner>
}
